'use client'

import {
  BREAKPOINTS,
  Button,
  Column,
  FONTS,
  Heading,
  Row,
  Sticker
} from 'igualigual-ds'
import React, { Suspense } from 'react'
import Link from 'next/link'
import NavigationEvents from '@/components/common/NavigationEvents/NavigationEvents'

import styled from 'styled-components'

export const StyledTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 112px;

  div {
    width: fit-content;
  }
  img {
    height: 112px;
  }
  @media screen and (${BREAKPOINTS.tablet.media}) {
    font-size: 132px;
    img {
      height: 132px;
    }
  }
  @media screen and (${BREAKPOINTS.desktop.media}) {
    font-size: 168px;
    img {
      height: 168px;
    }
  }
`

export const StyledText = styled.div`
  text-align: center;
  font-family: ${FONTS.alternativeFont};
  p {
    padding: 36px 0px 48px 0px;
  }
`

export const Styled404 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
`

export default function NotFound() {
  return (
    <>
      <Suspense>
        <NavigationEvents acf={{ page_style: 'light' }} />
      </Suspense>

      <Row align="center" justify="center" direction="column">
        <Styled404>
          <Column align="center" sm={12} md={8} lg={6}>
            <StyledTitle>
              <span>4</span>
              <Sticker type={'todos-iguais'} color={'black'} />
              <span>4</span>
            </StyledTitle>

            <StyledText>
              <Heading tag="p" size="texts">
                Não encontramos esta página. Mas encontramos 2,4 horas por dia a
                mais em trabalho de cuidado não remunerado em mulheres do que em
                homens.
              </Heading>

              <Link href="/">
                <Button variant={'primary'} size={'medium'}>
                  VOLTAR
                </Button>
              </Link>
            </StyledText>
          </Column>
        </Styled404>
      </Row>
    </>
  )
}
