'use client'

import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState
} from 'react'
import { ReactLenis, useLenis } from '@studio-freight/react-lenis'
import React from 'react'
import ScrollTrigger from 'gsap/ScrollTrigger'
import gsap from 'gsap'
import { IMenus } from '@/components/common/Header/Header.interface'

export interface ContextProps {
  i18n?: { [x: string]: Record<string, string> | any }
  lang?: string
  translatedUri?: { [x: string]: Record<string, string> }
  setTranslatedUri?: Dispatch<
    SetStateAction<{ [x: string]: Record<string, string> } | any>
  >
  menus: IMenus
  themeOptions?: {
    socials?: Record<string, string>[]
  }
  dict?: Record<string, Record<string, string>>
  lenis?: any // Type explicitly if possible
  editions: IEditions
}

const GlobalContext = createContext<ContextProps>({
  i18n: {},
  lang: '',
  translatedUri: {},
  setTranslatedUri: () => {},
  menus: {},
  themeOptions: {},
  dict: {},
  editions: {
    total: 0,
    items: [],
    categories: []
  }
})

export const GlobalContextProvider = ({
  children,
  value
}: {
  children: React.ReactNode
  value: ContextProps
}) => {
  gsap.registerPlugin(ScrollTrigger)

  const [translatedUri, setTranslatedUri] = useState<
    ContextProps['translatedUri']
  >({})

  const lang = value.lang || ''
  const i18n = value.i18n || {}
  const menus = value.menus
  const themeOptions = value.themeOptions || {}
  const dict = value.dict || {}
  const editions = value.editions

  const lenis = useLenis(() => {
    // called every scroll
  })

  return (
    <GlobalContext.Provider
      value={{
        i18n,
        lang,
        translatedUri,
        setTranslatedUri,
        menus,
        themeOptions,
        dict,
        lenis,
        editions
      }}
    >
      <ReactLenis
        root
        options={{
          duration: 1.8,
          easing: (t: number) => Math.min(1, 1.001 - Math.pow(2, -10 * t))
        }}
      >
        {children}
      </ReactLenis>
    </GlobalContext.Provider>
  )
}

export const useGlobalContext = () => useContext(GlobalContext)

interface ICategoryItem {
  slug: string
  name: string
}

interface ICategory {
  name: string
  items: ICategoryItem[]
}

interface IRelatedArticle {
  title: string
  slug: string
  image: boolean
  categories: ICategory[]
}

interface IPostACF {
  related_articles?: IRelatedArticle[]
}

interface IPostItem {
  post_type: string
  id: number
  date: string
  categories: ICategory[]
  author: string
  title: string
  excerpt: string
  image: boolean
  slug: string
  acf: IPostACF | []
}

export interface IEditions {
  total: number
  items: IPostItem[]
  categories: ICategory[]
}
